<template>
  <div>
    <andes-header></andes-header>
    <div class="container checkin-page" v-if="!loading">
      <div class="row">
        <div class="col-12">
          <h3>PASAJEROS PARA REALIZAR CHECK-IN</h3>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-12">
          <h5>Por favor asegurese que la información que se encuentra debajo sea correcta antes de realizar el
            Check-in.</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12 checkin-flight">
            <span>OY {{checkinFlight.Legs[0].InventoryLeg.FlightNumber}} <b>{{checkinFlight.DepartureStation}}
            <img src="@/img/plane-32.png" alt="Flying to">
            {{checkinFlight.ArrivalStation}}</b> {{getFormatDate}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="card shadow">
            <div class="card-header">
              <div class="row" v-for="(pax,i) of paxs">
                <div class="col-4">
                  <input type="checkbox" id="checkbox" v-if="!isChecked(pax)" @change="setToCheck(pax)"
                         v-model="passengers[pax.PassengerID]">
                  <i v-if="isChecked(pax)"><span class="fa fa-check-circle"></span></i>
                </div>
                <div class="col-6">
                  <h5>{{pax.LastName}}, {{pax.FirstName}} {{pax.MiddleName}}</h5>
                </div>
                <div class="col-2">
                  <h5>{{getPaxSeat(pax)}}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="d-inline-block w-100 mt-3">
            <div class="pull-left">
              <input type="checkbox" id="terms" v-model="terms">
              <label class="ml-3">Acepto los <a class="terms" @click="showTerms()">términos y condiciones</a></label>
            </div>
            <div class="pull-right">
              <button type="button" class="btn btn-outline-danger" @click="back">Volver</button>
              <button type="button" class="btn btn-andes" @click="onContinue" :disabled="segmentsToCheck.length==0 || !terms">Continuar</button>
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-4">
        </div>
      </div>
    </div>
    <loading-modal v-else></loading-modal>
    <andes-footer></andes-footer>
    <CheckinTermsPT show.sync="show" v-if="show && lang=='pt'"></CheckinTermsPT>
    <CheckinTerms show.sync="show" v-else-if="show"></CheckinTerms>
  </div>
</template>

<script>
  import { checkin, getBooking } from '@/api/api';
  import {mapState} from 'vuex';
  import AndesHeader from "../components/AndesHeader";
  import LoadingModal from "../components/LoadingModal";
  import AndesFooter from "../components/AndesFooter";
  import CheckinTerms from "../components/CheckinTerms";
  import CheckinTermsPT from "../components/CheckinTermsPT";
  import moment from 'moment';
  import { normalizeText  } from '@/utils/tools';

  export default {
    components: {
      LoadingModal,
      AndesHeader,
      AndesFooter,
      CheckinTerms,
      CheckinTermsPT
    },
    name: 'checkin-page',
    data: function () {
      return {
        passengers: {},
        paxs: [],
        terms: false,
        show: false,
        lang: 'es'

      }
    },
    mounted: function () {
      let language = navigator.language || navigator.userLanguage;
      if (language) this.lang = language.substring(0, 2);
      let r = [];
      for (let pax of this.booking.Passengers) {
          if (this.lastName && this.tlc(normalizeText(this.lastName)) != this.tlc(normalizeText(pax.LastName))) continue;
          if (this.dni && this.tlc(this.dni) != this.tlc(pax.DocNumber)) continue;
          r.push(pax);
      }
      this.paxs = r;

      for (let pax of this.paxs) {

        this.passengers[pax.PassengerID] = this.isChecked(pax);
      }

      this.$store.commit('clearSegmentToCheck')
    },
    computed: {
      ...mapState(['booking', 'loading', 'checkinFlight', 'segmentsToCheck', 'lastName', 'dni']),
      getFormatDate: function () {
        return moment(this.checkinFlight.DepartureDate).locale('es').format('DD MMMM YYYY')
      }
    },
    methods: {
      isChecked: function (pax) {
        let segment = _.find(pax.Segments, (c) => c.DepartureStation == this.checkinFlight.DepartureStation &&
          c.ArrivalStation == this.checkinFlight.ArrivalStation && c.DepartureDate == this.checkinFlight.DepartureDate);
        if (segment && segment.Legs[0].LiftStatus == 0) return false;
        return true;
      },
      setToCheck: function (pax) {
        let segment = _.find(pax.Segments, (c) => c.DepartureStation == this.checkinFlight.DepartureStation &&
          c.ArrivalStation == this.checkinFlight.ArrivalStation && c.DepartureDate == this.checkinFlight.DepartureDate);
        if (segment) {
          // console.log(this.passengers[pax.PassengerID])
          if (this.passengers[pax.PassengerID]) {
            this.$store.commit('addSegmentToCheck', segment.SegmentID)
            if (this.lastName) {
                this.$store.commit('setPassengerID', pax.PassengerID)
            }
          } else {
            this.$store.commit('removeSegmentToCheck', segment.SegmentID)
          }
        }
      },
      getPaxSeat: function (pax) {
        let segment = _.find(pax.Segments, (c) => c.DepartureStation == this.checkinFlight.DepartureStation &&
          c.ArrivalStation == this.checkinFlight.ArrivalStation && c.DepartureDate == this.checkinFlight.DepartureDate);
        if (segment && segment.Legs[0].LiftStatus == 1) {
          return segment.Legs[0].UnitDesignator
        }
        return '';
      },
      back: function () {
        this.$store.commit('checkinFlight', null);
        this.$router.replace("itinerary");
      },
      onContinue: function () {
        if (!this.booking.PassengersInfoCompleted || !this.booking.ContactInfoCompleted) {
          this.$router.replace("passenger-uncompleted");
        } else {
          this.$router.replace("seatmap");
        }
        return;
          /*let promises = []
          for (var segmentId of this.segmentsToCheck) {

            for (var pax of this.booking.Passengers) {
              for (var seg of pax.Segments) {

                if (seg.SegmentID == segmentId) {
                  this.$store.commit('loading', true);
                  let params = {
                    'RecordLocator': this.booking.RecordLocator,
                    'LegNumber': seg.Legs[0].LegNumber,
                    'UnitDesignator': seg.Legs[0].UnitDesignator,
                    'SegmentID': seg.SegmentID,
                    "InventoryLegID": seg.Legs[0].InventoryLegID,
                    "FlightId": seg.Legs[0].InventoryLeg.FlightId,
                  }
                  promises.push(checkin(params).then(r => r.json()));
                }
              }
            }
          }

          Promise.all(promises)
            .then(results => {
              var queryParams = {
                rl: this.booking.RecordLocator,
                ln: this.booking.Passengers[0].LastName,
              }
              getBooking(queryParams, (result) => {
                this.$store.dispatch('updateBooking', result);
                this.$router.replace('itinerary');
                this.$store.commit('loading', false);
              });
            })*/
      },
      tlc (t) {
          return t.replace(/ /g, '').toLowerCase();
      },
      showTerms () {
        this.show = true;
      }
    },
  }
</script>

<style scoped>
.terms {
  cursor: pointer !important;
  text-decoration: underline !important;

}
</style>
